import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const JamesChungHeadshot = () => (
  <div className="sd-teammate-headshot">
    <StaticQuery
      query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "JamesChungHeadshot.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
      render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid} />}
    />
  </div>);

export default JamesChungHeadshot;
