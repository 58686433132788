/** @flow
 * StratoDem Analytics : index
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';
import JamesChungHeadshot from "../../../components/images/JamesChungHeadshot";
import Layout from '../../../components/layout'

import SEO from '../../../components/SEO/seo'
import SDTeammate from '../../../components/SDTeammate/SDTeammate';

import './team.scss';

const About = () => (
  <Layout>
    <SEO
      title="James Chung"
      keywords={['real estate research', 'stratodem', 'analytics', 'portfolio analysis', 'demographics']}
      description="StratoDem Analytics managing team - James Chung"
    />
    <div style={{marginTop: 42}}>
      <SDTeammate
        name="James Chung"
        title="Managing Partner"
        email="james@stratodem.com"
        linkedin="https://www.linkedin.com/in/james-chung-04b64/"
        education={{
          university: 'Harvard University',
          degrees: ['MBA, Harvard Business School', 'AB, Harvard College'],
        }}
        bio="is a co-founder of StratoDem Analytics. He also serves as President of Reach Advisors, where he leads the firm’s strategy, research and predictive analytics work helping clients sharpen their most-pressing strategy and capital commitment decisions. In that capacity, James serves some of the nation’s leading real estate investment private equity funds, REITs and developers. Prior to founding Reach Advisors, James founded and then sold an online media and research firm in 2001, and before that, James served one of the nation’s leading marketers while at the Leo Burnett ad agency, where his two primary responsibilities involved pioneering data warehouse analytics and online media in the earliest years of those fields. James currently serves on the board of directors for the Harvard Alumni Association, and co-chairs a board committee on data and analytics. James also serves as the founder of an independent data science collaborative in Cambridge, MA for top doctoral-level and post-doctoral researchers working on breakthroughs in data science."
        headshotComponent={JamesChungHeadshot}
      />
    </div>
  </Layout>);

export default About;
